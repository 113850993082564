// import images

export const navData = [
  {
    name: "Home",
    href: "/",
  },
  {
    name: "Shop",
    href: "/shop",
  },

  {
    name: "About Us",
    href: "#AboutUs",
  },
];
